import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '@store/state/user.state';

const userSelector = createFeatureSelector<UserState>('user');

export const selectWorkshopData = createSelector(
  userSelector,
  user => user.workshopData
);

export const selectWorkshopClients = createSelector(
  selectWorkshopData,
  workshopData => workshopData?.clients,
);

export const selectWorkshopVerifiedMaintenances = createSelector(
  selectWorkshopData,
  workshopData => workshopData?.verifiedMaintenances,
);

export const selectWorkshopGeneratedInvoices = createSelector(
  selectWorkshopData,
  workshopData => workshopData?.billingData?.generatedInvoices,
);

export const selectWorkshopStockItems = createSelector(
  selectWorkshopData,
  workshopData => workshopData?.stock?.items,
);

export const selectWorkshopStockItem = (itemId: string) => createSelector(
  selectWorkshopData,
  workshopData => workshopData?.stock?.items.find(item => item.id === itemId),
);

export const selectWorkshopStockTransaction = (itemId: string, transactionId: string) => createSelector(
  selectWorkshopStockItem(itemId),
  stockItem => stockItem.transactions.find(transaction => transaction.id === transactionId),
);
